// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cli-js": () => import("./../../../src/pages/cli.js" /* webpackChunkName: "component---src-pages-cli-js" */),
  "component---src-pages-ecosystem-js": () => import("./../../../src/pages/ecosystem.js" /* webpackChunkName: "component---src-pages-ecosystem-js" */),
  "component---src-pages-examples-js": () => import("./../../../src/pages/examples.js" /* webpackChunkName: "component---src-pages-examples-js" */),
  "component---src-pages-external-links-js": () => import("./../../../src/pages/external-links.js" /* webpackChunkName: "component---src-pages-external-links-js" */),
  "component---src-pages-features-edge-js": () => import("./../../../src/pages/features/edge.js" /* webpackChunkName: "component---src-pages-features-edge-js" */),
  "component---src-pages-features-flex-js": () => import("./../../../src/pages/features/flex.js" /* webpackChunkName: "component---src-pages-features-flex-js" */),
  "component---src-pages-features-grid-js": () => import("./../../../src/pages/features/grid.js" /* webpackChunkName: "component---src-pages-features-grid-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-features-space-js": () => import("./../../../src/pages/features/space.js" /* webpackChunkName: "component---src-pages-features-space-js" */),
  "component---src-pages-features-typography-js": () => import("./../../../src/pages/features/typography.js" /* webpackChunkName: "component---src-pages-features-typography-js" */),
  "component---src-pages-features-utilities-js": () => import("./../../../src/pages/features/utilities.js" /* webpackChunkName: "component---src-pages-features-utilities-js" */),
  "component---src-pages-features-viewports-js": () => import("./../../../src/pages/features/viewports.js" /* webpackChunkName: "component---src-pages-features-viewports-js" */),
  "component---src-pages-guide-js": () => import("./../../../src/pages/guide.js" /* webpackChunkName: "component---src-pages-guide-js" */),
  "component---src-pages-helpers-rasters-js": () => import("./../../../src/pages/helpers/rasters.js" /* webpackChunkName: "component---src-pages-helpers-rasters-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

